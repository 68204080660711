<!--  -->
<template>
	<div class="myCourse-page">
		<div class="nav-wrap flex-ac">
			<!-- <div class="nav" :class="{ active: activeType == 1 }" @click="toogerNav(1)">全部</div> -->
			<div class="nav" :class="{ active: activeType == 2 }" @click="toogerNav(2)">收藏课程</div>
			<!-- <div class="nav" :class="{ active: activeType == 3 }" @click="toogerNav(3)">公开课</div> -->
		</div>
		<div class="course-list">
			<div class="course-container flex-ac" v-for="(item, index) in courseList" :key="index">
				<div class="course-cover-wrap">
					<img :src="item.kcimg" @click="goToCourse(item)" alt="" class="pointer cover" />
					<div class="status" v-if="item.isxiajia == 2">已下架</div>
				</div>
				<div class="course-info flex-jb flex-col">
					<div class="info-wrap flex ">
						<div class="type">{{ item.kctypename }}</div>
						<div class="course-title pointer textline-1" @click="goToCourse(item)">{{ item.kcname }}</div>
						<div class="time" v-if="item.kckstime">{{ item.kckstime }}至{{ item.kcjstime }}</div>
					</div>
					<div class="speed" v-if="item.kcxxjd">已学{{ item.kcxxjd }}</div>
					<div class="people flex-ac">
						<img src="@/assets/image/ls.png" alt="" />
						<span v-if="item.kcnums">{{ item.bmnums }} / {{ item.kcnums }}</span>
						<span v-else>{{ item.bmnums }}人学习</span>
					</div>
					<div class="info-wrap flex-end">
						<div class="progress textline-1" v-if="item.kctype == 1">{{ item.userwwcstr }}</div>
						<div class="info flex-ac flex1" v-else>
							<img src="@/assets/image/ls.png" alt="" />
							<div class="name">{{ item.teachername }}</div>
							<img src="@/assets/image/dd.png" alt="" />
							<div class="name">{{ item.taddress }}</div>
						</div>
						<div class="btn" @click="handleCollect(item)">取消收藏</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination flex-jc-ac">
			<el-pagination
				next-text="下一页"
				hide-on-single-page
				@current-change="getMyCollect"
				:current-page.sync="page"
				:page-size="limit"
				prev-text="上一页"
				layout="prev, pager, next"
				:total="allCourseCount"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
import { $getMyCollect, $cancelCollect } from "../../services/centerInfo";
export default {
	name: "MyCourse",
	data() {
		return {
			activeType: 2, // 1全部课程 2收藏课程 3公开课
			page: 1,
			limit: 10,
			courseList: [],
			allCourseCount: 0,
		};
	},
	components: {},

	computed: {},

	created() {
		this.getMyCollect();
	},

	methods: {
		async getMyCollect() {
			const { data } = await $getMyCollect({
				type: this.activeType,
				page: this.page,
				limit: this.limit,
			});
			this.allCourseCount = data.kcnum;
			this.courseList = data.list;
		},
		async toogerNav(type) {
			this.activeType = type;
			this.getMyCollect();
		},
		goToCourse(item) {
			if (item.isxiajia == 2) {
				this.$message.error("课程已下架~");
				return;
			}
			//1收藏课程
			if (item.kctype == 1) {
				this.$router.push({
					path: "/courseDetail",
					query: {
						id: item.kcid,
					},
				});
			} else {
				this.$router.push({
					path: "/openClassInfo",
					query: {
						id: item.kcid,
						title: item.kcname,
					},
				});
			}
		},
		async handleCollect(item) {
			const data = await $cancelCollect({
				myscid: item.id,
			});
			if (data) {
				this.$message.success("操作成功");
				this.getMyCollect();
			}
		},
	},
};
</script>

<style scoped lang="less">
.nav-wrap {
	height: 100px;
	padding-top: 35px;
	line-height: 65px;
	border-bottom: 1px solid #eeefef;
	font-size: 18px;
	color: #1c1f21;
	.nav {
		height: 100%;
		margin-right: 100px;
		cursor: pointer;
		user-select: none;
		&.active {
			font-weight: 600;
			border-bottom: 1px solid #fda019;
		}
	}
}
.course-list {
	.course-container {
		height: 190px;
		width: 975px;
		&:last-child {
			.course-info {
				border-bottom: none;
			}
		}
		.course-cover-wrap {
			width: 225px;
			height: 128px;
			position: relative;
			margin-right: 40px;
			.cover {
				width: 225px;
				height: 128px;
				border-radius: 4px;
			}
			.status {
				position: absolute;
				top: -4px;
				left: -4px;
				width: 68px;
				height: 26px;
				line-height: 26px;
				background: linear-gradient(72deg, #b2babe 0%, #d0d5d8 100%);
				border-radius: 12px 4px 12px 4px;
				font-size: 14px;
				text-align: center;
				font-weight: bold;
				color: #ffffff;
			}
		}
		.course-info {
			width: 710px;
			height: 190px;
			border-bottom: 1px solid #eeefef;
			padding: 35px 0 30px;
			.info-wrap {
				line-height: 32px;
				.type {
					flex: none;
					min-width: 72px;
					height: 32px;
					line-height: 22px;
					font-size: 12px;
					font-weight: 400;
					color: #8c9094;
					background: #f5f7fa;
					text-align: center;
					margin-right: 20px;
					padding: 5px;
					border-radius: 5px;
				}
				.course-title {
					flex: 1;
					font-size: 22px;
					line-height: 32px;
					font-weight: bold;
					color: #1c1f21;
				}
				.time {
					text-align: right;
					font-size: 16px;
					white-space: nowrap;
					margin-left: 10px;
					color: rgba(84, 92, 99, 0.5);
				}
			}
			.speed {
				font-size: 15px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #fa7419;
				line-height: 42px;
				background: linear-gradient(0deg, #fa7319 0%, #fda019 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.people {
				height: 30px;
				color: #545c63;
				img {
					height: 20px;
					margin-right: 10px;
				}
			}
			.info-wrap {
				justify-content: flex-end;
				height: 32px;
				.progress {
					flex: 1;
					font-size: 16px;
					font-weight: 400;
					color: #545c63;
					margin-right: 10px;
					line-height: 32px;
				}
				.info {
					img {
						height: 20px;
						margin-right: 10px;
					}
					.name {
						margin-right: 30px;
						color: #8c9094;
					}
				}
				.btn {
					width: 100px;
					height: 32px;
					font-size: 14px;
					text-align: center;
					color: #16abef;
					line-height: 30px;
					border: 1px solid #16abef;
					border-radius: 16px;
					user-select: none;
					cursor: pointer;
					&:hover {
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
						color: #ffffff;
						border: none;
						line-height: 32px;
					}
				}
			}
		}
	}
}
.pagination {
	margin-top: 50px;
	width: 100%;
}
.pointer {
	cursor: pointer;
}
</style>
